<template lang="html">
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12">
        <v-encounter-bar @encounterLoaded="onEncounterLoaded" @encounterUnloaded="onEncounterUnloaded"></v-encounter-bar>
      </v-col>
      <v-col cols="12" v-if="currentEncounter">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="Print">Print</v-tab>
            <v-tab key="Scan">Scan</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="Print">
              <v-document-print-list :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId"></v-document-print-list>
            </v-tab-item>
            <v-tab-item key="Scan">
              <v-document-scan :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" refreshable></v-document-scan>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'

export default {
  mixins: [encounterLoader],
}
</script>

<style lang="css" scoped>
</style>
